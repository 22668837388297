import red from "./red.png";
import studio from "./studio.png";
import advent from "./advent.png";
import aboutSquare from "./about_square.png";
import aboutRect from "../../static/me-small.png";

export const whatsNewSections = [
  {
    orientation: "left",
    title: "On the blog",
    text: `
Red mold-a-wax is a lesser known soft wax. But it’s a lot of fun to work with! 

In the newest blog post I cover tips and tricks on how to work with this wax. 
`,
    link: `/blog/post/get-started-with-red-moldawax`,
    linktext: `Read about red mold-a-wax`,
    image: red,
    imagealt: `Close up of bench peg with a big cylinder shaped chunk of red wax on top`,
  },

  {
    orientation: "right",
    title: `Behind the scenes`,
    text: `
I have so much to do so of course I took a day to rearrange my studio. 

Worth it! I love my new back to back set up! 
`,
    image: studio,
    imagealt:
      "Photo of my studio. 2 very messy benches back to back with a pink chair in between. In front of a window with a tree outside",
  },

  {
    orientation: "left",
    title: `With the classes`,
    text: `
The advent calendars are back for sale for the rest of the year! If you're looking for a creative way to end the year this is it!

An advent calendars is good if you want to learn a little bit about a lot of different topics! Hard wax, soft wax, melting, carving, it’s all included!
`,
    link: `advent-calendar`,
    linktext: `Show me the advent calendars!`,
    image: advent,
    imagealt: "Benchpeg with a green wax and blue wax Christmas tree on top",
  },
];

export const blurbsection = {
  text: `Wax carving is a fun jewellery making technique where you transform an unassuming piece of wax into a beautiful piece of jewellery! Learn to carve rings,  3-dimensional shapes, classic pieces, and bold statement jewellery. 

Anything is possible in wax!

Through video tutorials you learn to carve your own piece of jewellery ready to be cast in the metal of your choice. Want to know what you can make?`,
  linktext: `Yes! Show me the classes!`,
  link: `/classes`,
};

export const aboutsection = {
  title: `Hi I'm Sandy!`,
  text: `I’m the CEO (Chief EVERYTHING Officer) here at Wax Carvers.
  
I did my first jewellery making class in 2012 and haven’t looked back since. I fell in love with wax carving in 2018 and I’m excited to share that love with you!
`,
  linktext: `Want to know more?`,
  link: `/about`,
  squarephoto: aboutSquare,
  rectphoto: aboutRect,
};

export const blogsection = {
  text: `
Do you want to know what tools you need to get started with wax carving?

Do you want to know what wax color you should get?

Do you want to know how you can set up a quick bench when you don’t have space for a dedicated one?

All these topics and more are covered on the blog! A new post is published every other Tuesday. So you can keep coming back to learn more about wax carving.
`,
  linktext: `Let's read that blog!`,
  link: `/blog`,
};
